<template>
	<div class="step-3 animate-in " :class="{loaded: isLoaded, 'is-submitted':isSubmitted}">
		<b-form @submit.prevent="submit">
			<h2 class="ul">Additional Information</h2>
			<dynamic-form-controls :questions="questions" @change="validate" />
			<div v-if="questions.length" class="d-flex form-controls">
				<btn-back />
				<btn-save />	
				<b-button type="submit">Next</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>
import isQuestionView from '@/mixins/isQuestionView'
export default {
	name: 'Step3',
	mixins: [isQuestionView],
	data() {
		return {
			questionGroup: 'additional',
			nextStep: 'review',
			prevStep: 'step2'
		}
	},
	metaInfo: {
		title: 'Submit Report - Step 3',
	}
}
</script>